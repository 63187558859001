import { Checkbox, CheckboxGroup, Content, Grid, Heading, IllustratedMessage, ProgressCircle, SearchField, View } from '@adobe/react-spectrum';
import { useIsSSR } from '@react-aria/ssr';
import { isAndroid, isIOS } from '@react-aria/utils';
import { useBreakpoint } from '@react-spectrum/utils';
import { SpectrumCheckboxGroupProps } from '@react-types/checkbox';
import { SpectrumSearchFieldProps } from '@react-types/searchfield';
import { DOMRefValue } from '@react-types/shared';
import NoSearchResults from '@spectrum-icons/illustrations/NoSearchResults';
import { AnimatePresence, motion } from 'framer-motion';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import Footer from '../components/Footer';
import JobPost from '../components/JobPost';
import LoadingState from '../components/LoadingState';
import NewsletterSubscription from '../components/NewsletterSubscription';
import WebcomicAppAd from '../components/WebcomicAppAd';
import useOpenErrorDialog from '../hooks/useOpenErrorDialog';
import heroImageDesktop from '../public/images/hero-1-desktop.webp';
import heroImageMobile from '../public/images/hero-1-mobile.webp';
import { getBaseUrl } from '../utils';

interface APIGetJobsResponse {
  jobs: {
    company_logo_file_name: string;
    company_name: string;
    company_website: string;
    date: string;
    description: string;
    description_language: string;
    email_address: string;
    id: number;
    location: string;
    post_link: string;
    salary: string;
    title: string;
    type: string;
    url: string;
  }[];
  totalFilteredNumberOfJobs: number;
  totalNumberOfJobs: number;
}

export interface Job {
  companyLogoFileName?: string;
  companyName: string;
  companyWebsite: string;
  date: string;
  description: string;
  descriptionLanguage: string;
  emailAddress: string;
  id: string;
  jobPostLink: string;
  location: string;
  salary: string;
  title: string;
  type: string;
  url: string;
}

const transformJobs = (jobs: APIGetJobsResponse['jobs']) => jobs.map(({
  company_logo_file_name,
  company_name,
  company_website,
  date,
  description,
  description_language,
  email_address,
  id,
  location,
  post_link,
  salary,
  title,
  type,
  url
}): Job => ({
  companyLogoFileName: company_logo_file_name,
  companyName: company_name,
  companyWebsite: company_website,
  date,
  description,
  descriptionLanguage: description_language,
  emailAddress: email_address,
  id: id.toString(),
  jobPostLink: post_link,
  location,
  salary,
  title,
  type,
  url
}));

interface Props {
  initialJobs: Job[];
  initialTotalFilteredNumberOfJobs: number;
  initialTotalNumberOfJobs: number;
  jobTypes: string[];
  searchText: string;
  selectedJobTypes: string[];
  setSearchText: Dispatch<SetStateAction<string>>;
  setSelectedJobTypes: Dispatch<SetStateAction<string[]>>;
}

const title = 'Illustration Jobs';
const description = 'Jobs for illustrators.';
const imageUrl = 'https://illustration-jobs.com/images/logo-512.png';

const Home: NextPage<Props> = ({
  initialJobs,
  initialTotalFilteredNumberOfJobs,
  initialTotalNumberOfJobs,
  jobTypes,
  searchText,
  selectedJobTypes,
  setSearchText,
  setSelectedJobTypes
}) => {
  const { matchedBreakpoints } = useBreakpoint()!;
  const isInBaseBreakpointRange = !matchedBreakpoints.includes('S');
  const isMobileDevice = isAndroid() || isIOS();
  const { errorDialog, openErrorDialog } = useOpenErrorDialog();
  const isSSR = useIsSSR();

  const controllerRef = useRef<AbortController>();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [jobs, setJobs] = useState<Job[] | null>(initialJobs);
  const [page, setPage] = useState(1);
  const [totalFilteredNumberOfJobs, setTotalFilteredNumberOfJobs] = useState(initialTotalFilteredNumberOfJobs);

  useEffect(() => {
    (async () => {
      if (controllerRef.current) {
        try {
          controllerRef.current.abort('...');
        } catch (error) {
          console.error(error);
        }
      }

      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;

      const params = new URLSearchParams({
        jobType: selectedJobTypes.join(','),
        page: page.toString(),
        searchText
      });

      const response = await fetch(`/api/get-jobs?${params}`, { signal });

      if (!response.ok) {
        openErrorDialog();

        throw new Error(`${response.status} (${response.statusText})`);
      }

      const { jobs, totalFilteredNumberOfJobs } = await response.json() as APIGetJobsResponse;

      setJobs(transformJobs(jobs));
      setTotalFilteredNumberOfJobs(totalFilteredNumberOfJobs);
      setIsLoadingMore(false);
    })();
  }, [openErrorDialog, page, searchText, selectedJobTypes]);

  const handleSearchTextChange: SpectrumSearchFieldProps['onChange'] = value => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setJobs(null);
      setPage(1);
      setSearchText(value);
    }, 500);
  };

  const handleSelectedJobTypesChange: SpectrumCheckboxGroupProps['onChange'] = value => {
    setJobs(null);
    setPage(1);
    setSelectedJobTypes(value);
  };

  return (
    <>
      <Head>
        <title>
          {title}
        </title>
        <meta
          content={description}
          name="description"
        />
        <meta
          content="summary"
          name="twitter:card"
        />
        <meta
          content="@illust_jobs"
          name="twitter:site"
        />
        <meta
          content={title}
          name="twitter:title"
        />
        <meta
          content={description}
          name="twitter:description"
        />
        <meta
          content={imageUrl}
          name="twitter:image"
        />
        <meta
          content={title}
          name="og:title"
        />
        <meta
          content={description}
          name="og:description"
        />
        <meta
          content={imageUrl}
          name="og:image"
        />
        <meta
          content="https://illustration-jobs.com"
          name="og:url"
        />
      </Head>
      {isSSR ? <LoadingState /> : (
        <View paddingX={{ base: 'size-100', S: 'size-200' }}>
          <Grid
            {...isInBaseBreakpointRange && {
              UNSAFE_style: {
                boxSizing: 'border-box',
                paddingBottom: 'var(--spectrum-global-dimension-size-700)',
                paddingLeft: 'var(--spectrum-global-dimension-size-100)',
                paddingRight: 'var(--spectrum-global-dimension-size-100)'
              }
            }}
            alignContent="center"
            height={{ base: `calc(100vh - ${isMobileDevice ? 70 : 56}px)`, S: 'size-6000' }}
            justifyContent="center"
            justifyItems="center"
            marginBottom={{ base: 'size-100', S: 'size-800' }}
            marginX={{ base: isMobileDevice ? -10 : -8, S: isMobileDevice ? -20 : -16 }}
            position="relative"
          >
            <Image
              alt="Illustration of a forest."
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              priority
              quality={100}
              {...isInBaseBreakpointRange ? {
                src: heroImageMobile
              } : {
                objectPosition: 'center 90%',
                src: heroImageDesktop
              }}
            />
            <Heading
              UNSAFE_style={{
                backgroundColor: 'var(--spectrum-global-color-gray-100)',
                color: 'var(--spectrum-alias-heading-text-color)',
                lineHeight: 'var(--spectrum-alias-heading-text-line-height)',
                textAlign: 'center',
                ...isInBaseBreakpointRange ? {
                  fontSize: 'var(--spectrum-alias-heading2-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100)'
                } : {
                  fontSize: 'var(--spectrum-alias-heading-display2-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100) var(--spectrum-global-dimension-size-200)'
                }
              }}
              level={1}
              marginBottom="heading-margin-bottom"
              marginTop="size-0"
              zIndex={1}
            >
              {'The free job board for illustrators.'}
            </Heading>
            <Heading
              UNSAFE_style={{
                backgroundColor: 'var(--spectrum-global-color-gray-100)',
                color: 'var(--spectrum-alias-heading-text-color)',
                lineHeight: 'var(--spectrum-alias-heading-text-line-height)',
                textAlign: 'center',
                ...isInBaseBreakpointRange ? {
                  fontSize: 'var(--spectrum-alias-heading4-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100)'
                } : {
                  fontSize: 'var(--spectrum-alias-heading2-text-size)',
                  padding: 'var(--spectrum-global-dimension-size-100) var(--spectrum-global-dimension-size-200)'
                }
              }}
              level={2}
              marginBottom="size-0"
              marginTop="size-0"
              zIndex={1}
            >
              {`${initialTotalNumberOfJobs} jobs posted.`}
            </Heading>
          </Grid>
          <Grid
            alignItems={{ S: 'start' }}
            columnGap={{ S: 'size-200' }}
            columns={{
              L: isMobileDevice ? ['minmax(0, 2fr)', 'minmax(0, 1fr)'] : ['minmax(0, 1fr)', 'minmax(0, 3fr)', 'minmax(0, 1fr)'],
              M: isMobileDevice ? ['1fr'] : ['minmax(0, 2fr)', 'minmax(0, 1fr)'],
              S: ['1fr']
            }}
            marginX={{ S: 'auto' }}
            maxWidth={{ S: 1280 }}
            rowGap={{ base: 'size-100', S: isMobileDevice ? 'size-100' : 'size-0' }}
          >
            <View
              backgroundColor="gray-50"
              borderColor="light"
              borderRadius="regular"
              borderWidth="thin"
              elementType="aside"
              padding="size-200"
              top={{ S: 'size-1200' }}
              {...isMobileDevice ? {
                isHidden: { L: true },
                order: { base: 2 }
              } : {
                isHidden: { L: false, S: true },
                order: { base: 2, S: 1 },
                position: { S: 'sticky' }
              }}
            >
              <SearchField
                label="Search jobs, companies and locations"
                marginBottom="size-200"
                onChange={handleSearchTextChange}
                width="100%"
              />
              <CheckboxGroup
                label="Type"
                onChange={handleSelectedJobTypesChange}
                value={selectedJobTypes}
              >
                {jobTypes.map(jobType => (
                  <Checkbox
                    key={jobType}
                    value={jobType}
                  >
                    {jobType}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </View>
            {!jobs ? (
              <View
                elementType="main"
                order={isMobileDevice ? { base: 2, L: 1 } : { base: 3, S: 2 }}
              >
                <Grid
                  alignContent="center"
                  justifyContent="center"
                  minHeight="size-3000"
                >
                  <ProgressCircle
                    aria-label="Loading..."
                    isIndeterminate
                  />
                </Grid>
              </View>
            ) : (
              <View
                elementType="main"
                order={isMobileDevice ? { base: 2, L: 1 } : { base: 3, S: 2 }}
              >
                {jobs.length === 0 ? (
                  <IllustratedMessage
                    height="auto"
                    marginTop="size-400"
                  >
                    <NoSearchResults />
                    <Heading>
                      {'No matching results'}
                    </Heading>
                    <Content>
                      {'Try another search.'}
                    </Content>
                  </IllustratedMessage>
                ) : (
                  <>
                    <Grid rowGap="size-100">
                      {jobs.map(job => (
                        <JobPost
                          key={job.id}
                          job={job}
                          searchText={searchText}
                        />
                      ))}
                    </Grid>
                    {jobs.length < totalFilteredNumberOfJobs ? (
                      <LoadMore
                        isLoadingMore={isLoadingMore}
                        setIsLoadingMore={setIsLoadingMore}
                        setPage={setPage}
                      />
                    ) : (
                      <View minHeight="size-1700" />
                    )}
                  </>
                )}
              </View>
            )}
            <View
              elementType="aside"
              top={{ S: 'size-1200' }}
              {...isMobileDevice ? {
                order: { base: 1, L: 2 },
                position: { L: 'sticky' }
              } : {
                isHidden: { M: false, S: true },
                order: { base: 1, S: 3 },
                position: { S: 'sticky' }
              }}
            >
              <NewsletterSubscription />
              <WebcomicAppAd />
            </View>
          </Grid>
          <Grid
            columnGap={{ S: 'size-200' }}
            columns={{
              L: isMobileDevice ? ['1fr'] : ['minmax(0, 1fr)', 'minmax(0, 3fr)', 'minmax(0, 1fr)'],
              S: ['1fr']
            }}
            marginBottom={{ base: 'size-200', S: 'size-400' }}
            marginX={{ S: 'auto' }}
            maxWidth={{ S: 1280 }}
          >
            <View isHidden={isMobileDevice ? { S: true } : { L: false, S: true }} />
            <Footer isOnHomePage />
            <View isHidden={isMobileDevice ? { S: true } : { M: false, S: true }} />
          </Grid>
        </View>
      )}
      {errorDialog}
    </>
  );
};

interface LoadMoreProps {
  isLoadingMore: boolean;
  setIsLoadingMore: Dispatch<SetStateAction<boolean>>;
  setPage: Dispatch<SetStateAction<number>>;
}

// eslint-disable-next-line react/no-multi-comp
const LoadMore = ({
  isLoadingMore,
  setIsLoadingMore,
  setPage
}: LoadMoreProps) => {
  const gridRef = useRef<DOMRefValue<HTMLDivElement> | null>(null);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(([ entry ]) => {
      if (entry.isIntersecting) {
        setIsLoadingMore(true);
        setPage(page => page + 1);
      }
    });

    intersectionObserver.observe(gridRef.current!.UNSAFE_getDOMNode()!);

    return () => {
      intersectionObserver.disconnect();
    };
  }, [setIsLoadingMore, setPage]);

  return (
    <Grid
      ref={gridRef}
      alignContent="center"
      justifyContent="center"
      minHeight="size-1700"
    >
      <AnimatePresence
        exitBeforeEnter
        initial={false}
      >
        {isLoadingMore && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <ProgressCircle
              aria-label="Loading..."
              isIndeterminate
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Grid>
  );
};

export const getServerSideProps: GetServerSideProps = async context => {
  const params = new URLSearchParams({
    jobType: 'Full-time,Freelance / Contract,Part-time,Internship',
    page: '1',
    searchText: ''
  });

  const response = await fetch(`${getBaseUrl(context.req)}/api/get-jobs?${params}`);

  if (!response.ok) {
    throw new Error(`${response.status} (${response.statusText})`);
  }

  const { jobs, totalFilteredNumberOfJobs: initialTotalFilteredNumberOfJobs, totalNumberOfJobs: initialTotalNumberOfJobs } = await response.json() as APIGetJobsResponse;

  return {
    props: {
      initialJobs: transformJobs(jobs),
      initialTotalFilteredNumberOfJobs,
      initialTotalNumberOfJobs
    }
  };
};

export default Home;
